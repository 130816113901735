@mixin dark {
  @media (prefers-color-scheme: dark) {
    @content;
  }
  [data-theme="dark"] & {
    @content;
  }
}

@mixin light {
  [data-theme="light"] & {
    @content;
  }
}

@mixin sepia {
  @media (prefers-color-scheme: light) {
    @content;
  }
  [data-theme="sepia"] & {
    @content;
  }
}
